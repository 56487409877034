<template>
  <div>
    <div class="me">
      <div class="container">
        <img
          class="avatar"
          v-if="detail.avatar_img"
          :src="detail.avatar_img"
          alt=""
        />
        <div class="content">
          <div class="name">{{ detail.name }}</div>
          <div class="description">
            <span> {{ detail.email }}</span>
          </div>
        </div>

        <a-dropdown
          placement="bottomRight"
          style="margin-left: auto"
          :trigger="['click']"
        >
          <a-icon type="setting" class="setting" />

          <a-menu slot="overlay">
            <a-menu-item @click="changeActive('my-password')">
              修改密码
            </a-menu-item>
            <a-menu-item @click="logout">
              {{ L("退出") }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>

    <div class="me-wrapper">
      <div class="container">
        <div class="menu">
          <div
            class="menu-item"
            :class="active === 'my-info' ? 'active' : ''"
            @click="changeActive('my-info')"
          >
            {{ L("我的简历") }}
          </div>
          <div
            class="menu-item"
            :class="active === 'my-collection' ? 'active' : ''"
            @click="changeActive('my-collection')"
          >
            {{ L("我的收藏") }}
          </div>

          <div
            class="menu-item"
            :class="active === 'my-activity' ? 'active' : ''"
            @click="changeActive('my-activity')"
          >
            {{ L("我的活动") }}
          </div>

          <div
            class="menu-item"
            :class="active === 'sign-up' ? 'active' : ''"
            @click="changeActive('sign-up')"
          >
            {{ L("已投岗位") }}
          </div>

          <div
            class="menu-item"
            :class="active === 'my-story' ? 'active' : ''"
            @click="changeActive('my-story')"
          >
            {{ L("实习经历") }}
          </div>
        </div>

        <div class="detail">
          <my-info v-if="active === 'my-info'" />
          <my-collection v-if="active === 'my-collection'" />
          <my-activity v-if="active === 'my-activity'" />
          <sign-up v-if="active === 'sign-up'" />
          <my-story v-if="active === 'my-story'" />
          <my-password v-if="active === 'my-password'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyInfo from "./components/my-info";
import MyCollection from "./components/my-collection";
import MyActivity from "./components/my-activity";
import SignUp from "./components/sign-up";
import MyStory from "./components/my-story";
import MyPassword from "./components/my-password";
import "./index.css";
import { fetchResume } from "@/api/me";
export default {
  components: {
    MyInfo,
    MyCollection,
    MyActivity,
    SignUp,
    MyStory,
    MyPassword,
  },
  data() {
    return {
      active: "my-info",
      detail: {},
    };
  },

  mounted() {
    fetchResume().then((res) => {
      this.detail = res;
    });
  },

  methods: {
    changeActive(type) {
      this.active = type;
    },

    logout() {
      this.$confirm({
        title: this.L("是否退出？"),
        okText: this.L("是"),
        cancelText: this.L("否"),
        onOk() {
          window.localStorage.clear();
          window.open("https://dg.upapi.cn/portal/#/", "_self");
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.me {
  background: #004BB0;
  padding-bottom: 34px;
  color: #fff;
}
.me .container {
  display: flex;
  align-items: center;
  padding: 24px;
}

.me .avatar {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 24px;
}
.me .name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.me .description {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.setting {
  cursor: pointer;
}

.me-wrapper {
  background-color: #f3f3f3;
  padding-top: 25px;
  padding-bottom: 36px;
}

.me-wrapper .container {
  background-color: #fff;
  display: flex;
  padding: 24px;
}

.me-wrapper .menu {
  padding-right: 24px;
  margin-right: 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.me-wrapper .menu .menu-item {
  font-size: 16px;
  padding-bottom: 24px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  cursor: pointer;
}
.me-wrapper .menu .active {
  color: #004BB0;
}
.me-wrapper .menu .logout {
  padding: 6px 24px;
  background: #004BB0;
  color: #fff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.me-wrapper .detail {
  flex: 1;
}
</style>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .me {
    padding-bottom: 12px;
  }
  .me .container {
    padding: 12px;
  }

  .me .avatar {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .me .name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .me .description {
    font-size: 12px;
    font-weight: 300;
    display: flex;
    align-items: center;
  }

  .me-wrapper {
    background-color: #f3f3f3;
    padding: 12px 0;
  }

  .me-wrapper .container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .me-wrapper .menu {
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-right-width: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .me-wrapper .menu .menu-item {
    font-size: 14px;
    padding-bottom: 0;
  }
}
</style>